import Multicall from 'abis/Multicall.json'
import Token from 'abis/Token.json'
import { getContract } from 'config/contracts'
import { getV2Tokens, NATIVE_TOKEN_ADDRESS } from 'config/tokens'
import { BigNumber } from 'ethers'
import { PLACEHOLDER_ACCOUNT } from 'rfx/lib/legacy'
import { useMulticall } from 'rfx/lib/multicall'
import { TokenBalancesData } from './types'
import useWallet from 'rfx/lib/wallets/useWallet'

type BalancesDataResult = {
  error?: Error
  balancesData?: TokenBalancesData
}

export function useTokenBalances(
  chainId: number,
  overrideAccount?: string | undefined,
  overrideTokenList?: {
    address: string
    isSynthetic?: boolean
  }[],
  refreshInterval?: number,
): BalancesDataResult {
  const { account: currentAccount } = useWallet()
  const account = overrideAccount ?? currentAccount

  const { data, error } = useMulticall(chainId, `useTokenBalances-${chainId}`, {
    key: account
      ? [account, ...(overrideTokenList || []).map((t) => t.address), chainId]
      : null,

    refreshInterval,

    request: () => {
      const _reqs = getV2Tokens(chainId).reduce((acc: any, token) => {
        // Skip synthetic tokens
        if (token.isSynthetic) {
          return acc
        }
        const address = token.address

        if (address === NATIVE_TOKEN_ADDRESS) {
          acc[address] = {
            contractAddress: getContract(chainId, 'Multicall'),
            abi: Multicall.abi,
            calls: {
              balance: {
                methodName: 'getEthBalance',
                params: [account],
              },
            },
          }
        } else {
          acc[address] = {
            contractAddress: address,
            abi: Token.abi,
            calls: {
              balance: {
                methodName: 'balanceOf',
                params: [account ?? PLACEHOLDER_ACCOUNT],
              },
            },
          }
        }
        return acc
      }, {})

      return _reqs
    },
    parseResponse: (res) => {
      const response = Object.keys(res.data).reduce(
        (tokenBalances: TokenBalancesData, tokenAddress) => {
          tokenBalances[tokenAddress] = BigNumber.from(
            res.data[tokenAddress].balance.returnValues[0] || 0,
          )

          return tokenBalances
        },
        {} as TokenBalancesData,
      )

      return response
    },
  })

  return {
    error,
    balancesData: data,
  }
}
