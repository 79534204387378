import ExchangeRouter from 'abis/ExchangeRouter.json'
import { getContract } from 'config/contracts'
import { NATIVE_TOKEN_ADDRESS, convertTokenAddress } from 'config/tokens'
import { SetPendingWithdrawal } from 'context/SyntheticsEvents'
import { BigNumber, Signer, ethers } from 'ethers'
import { isAddressZero } from 'rfx/lib/legacy'
import {
  PendingTransaction,
  SendPaymasterTransactionFn,
} from 'hooks/usePaymaster'
import { encodeFunctionData } from 'viem'
import { simulateExecuteOrderTxn } from '../orders/simulateExecuteOrderTxn'
import { TokensData } from '../tokens'
import { applySlippageToMinOut } from '../trade'
import {
  DecreasePositionSwapType,
  SwapOrderParams,
  isMarketOrderType,
} from '../orders'
import { Subaccount } from 'context/SubaccountContext/SubaccountContext'
import { compact } from 'lodash'
import { getGasLimit } from 'rfx/lib/contracts'
type Params = {
  account: string
  marketTokenAddress: string
  marketTokenAmount: BigNumber
  initialLongTokenAddress: string
  minLongTokenAmount: BigNumber
  longTokenSwapPath: string[]
  initialShortTokenAddress: string
  shortTokenSwapPath: string[]
  minShortTokenAmount: BigNumber
  executionFee: BigNumber
  allowedSlippage: number
  skipSimulation?: boolean
  tokensData: TokensData
  setPendingTxns: (txns: PendingTransaction[]) => void
  setPendingWithdrawal: SetPendingWithdrawal
  pricesUpdatedAt?: number
}

export enum SwapPricingType {
  TwoStep = 0,
  Shift = 1,
  Atomic = 2,
}
export async function createWithdrawalTxn(
  chainId: number,
  signer: Signer,
  sendPaymasterTransaction: SendPaymasterTransactionFn,
  p: Params,
  q?: SwapOrderParams,
) {
  const contract = new ethers.Contract(
    getContract(chainId, 'ExchangeRouter'),
    ExchangeRouter.abi,
    signer,
  )
  const withdrawalVaultAddress = getContract(chainId, 'WithdrawalVault')

  const isNativeWithdrawal =
    isAddressZero(p.initialLongTokenAddress) ||
    isAddressZero(p.initialShortTokenAddress)

  const executionFee = p.executionFee

  const wntAmount = executionFee

  const initialLongTokenAddress = convertTokenAddress(
    chainId,
    p.initialLongTokenAddress,
    'wrapped',
  )
  const initialShortTokenAddress = convertTokenAddress(
    chainId,
    p.initialShortTokenAddress,
    'wrapped',
  )

  const minLongTokenAmount = applySlippageToMinOut(
    p.allowedSlippage,
    p.minLongTokenAmount,
  )
  const minShortTokenAmount = applySlippageToMinOut(
    p.allowedSlippage,
    p.minShortTokenAmount,
  )

  const swapEncodedPayload = q && (await getParams(signer, null, chainId, q))

  const withdrawMulticall = [
    { method: 'sendWnt', params: [withdrawalVaultAddress, wntAmount] },
    {
      method: 'sendTokens',
      params: [
        p.marketTokenAddress,
        withdrawalVaultAddress,
        p.marketTokenAmount,
      ],
    },
    {
      method: 'createWithdrawal',
      params: [
        {
          receiver: p.account,
          callbackContract: ethers.constants.AddressZero,
          market: p.marketTokenAddress,
          initialLongToken: initialLongTokenAddress,
          initialShortToken: initialShortTokenAddress,
          longTokenSwapPath: p.longTokenSwapPath,
          shortTokenSwapPath: p.shortTokenSwapPath,
          marketTokenAmount: p.marketTokenAmount,
          minLongTokenAmount,
          minShortTokenAmount,
          shouldUnwrapNativeToken: isNativeWithdrawal,
          executionFee: executionFee,
          callbackGasLimit: BigNumber.from(0),
          uiFeeReceiver: ethers.constants.AddressZero,
        },
      ],
    },
  ]

  const multicall = [...withdrawMulticall]

  const encodedPayload = compact(multicall)
    .filter(Boolean)
    .map((call) =>
      contract.interface.encodeFunctionData(call!.method, call!.params),
    )

  if (!p.skipSimulation) {
    await simulateExecuteOrderTxn(chainId, {
      account: p.account,
      primaryPriceOverrides: {},
      secondaryPriceOverrides: {},
      tokensData: p.tokensData,
      createOrderMulticallPayload: encodedPayload,
      method: 'simulateExecuteWithdrawal',
      errorTitle: `Withdrawal error.`,
      value: wntAmount,
      signer,
      extraArgs: [SwapPricingType.TwoStep],
    })
  }

  const calldata = encodeFunctionData({
    abi: ExchangeRouter.abi,
    args: [encodedPayload],
    functionName: 'multicall',
  })

  const gasLimit = await getGasLimit(
    contract,
    'multicall',
    [encodedPayload],
    BigNumber.from(wntAmount.toString()),
  )

  const call = {
    address: getContract(chainId, 'ExchangeRouter'),
    calldata,
    hideSentMsg: true,
    hideSuccessMsg: true,
    setPendingTxns: p.setPendingTxns,
    gas: swapEncodedPayload ? '7920027' : BigInt(gasLimit.toString()),
    value: BigInt(wntAmount.toString()),
  }

  const messageOpts = {
    hideSentMsg: true,
    hideSuccessMsg: true,
  }
  await sendPaymasterTransaction({
    call,
    account: p.account,
    messageOpts,
    setPendingTxns: p.setPendingTxns,
    router: contract,
    payload: [encodedPayload],
    method: 'multicall',
  })

  p.setPendingWithdrawal({
    account: p.account,
    marketAddress: p.marketTokenAddress,
    marketTokenAmount: p.marketTokenAmount,
    minLongTokenAmount,
    minShortTokenAmount,
    shouldUnwrapNativeToken: isNativeWithdrawal,
  })
}

export async function getParams(
  signer: Signer,
  subaccount: Subaccount,
  chainId: number,
  p: SwapOrderParams,
) {
  const isNativePayment = p.fromTokenAddress === NATIVE_TOKEN_ADDRESS
  const isNativeReceive = p.toTokenAddress === NATIVE_TOKEN_ADDRESS
  const orderVaultAddress = getContract(chainId, 'OrderVault')
  const wntSwapAmount = isNativePayment ? p.fromTokenAmount : BigNumber.from(0)
  const totalWntAmount = wntSwapAmount.add(p.executionFee)

  const initialCollateralTokenAddress = convertTokenAddress(
    chainId,
    p.fromTokenAddress,
    'wrapped',
  )

  const shouldApplySlippage = isMarketOrderType(p.orderType)

  const minOutputAmount = shouldApplySlippage
    ? applySlippageToMinOut(p.allowedSlippage, p.minOutputAmount)
    : p.minOutputAmount

  const initialCollateralDeltaAmount = p.fromTokenAmount

  const createOrderParams = {
    addresses: {
      receiver: p.account,
      cancellationReceiver: ethers.constants.AddressZero,
      initialCollateralToken: initialCollateralTokenAddress,
      callbackContract: ethers.constants.AddressZero,
      market: ethers.constants.AddressZero,
      swapPath: p.swapPath,
      uiFeeReceiver: ethers.constants.AddressZero,
    },
    numbers: {
      sizeDeltaUsd: BigNumber.from(0),
      initialCollateralDeltaAmount,
      triggerPrice: BigNumber.from(0),
      acceptablePrice: BigNumber.from(0),
      executionFee: p.executionFee,
      callbackGasLimit: BigNumber.from(0),
      minOutputAmount,
    },
    autoCancel: false,
    orderType: p.orderType,
    decreasePositionSwapType: DecreasePositionSwapType.NoSwap,
    isLong: false,
    shouldUnwrapNativeToken: isNativeReceive,
    referralCode: p.referralCode || ethers.constants.HashZero,
  }

  const multicall = [
    { method: 'sendWnt', params: [orderVaultAddress, totalWntAmount] },

    !isNativePayment
      ? {
          method: 'sendTokens',
          params: [p.fromTokenAddress, orderVaultAddress, p.fromTokenAmount],
        }
      : undefined,

    {
      method: 'createOrder',
      params: [createOrderParams],
    },
  ].filter(Boolean)

  return {
    minOutputAmount,
    totalWntAmount,
    encodedPayload: multicall,
  }
}
