import { PriceFeed } from '@pythnetwork/pyth-evm-js'
import { TokenPriceInfo } from '@store/commonStore'
import priceStore from '@store/priceStore'
import {
  NATIVE_TOKEN_ADDRESS,
  TOKENS,
  Token,
  getV1Tokens,
  getWrappedToken,
} from 'config/tokens'
import { parseContractPrice } from 'domain/synthetics/tokens'
import { BigNumber } from 'ethers'

export const tokenPythIdMapping = (chainId: number): Record<string, Token> => {
  const tokens = TOKENS[chainId]

  return tokens.reduce((acc, token: any) => {
    if (token.pythId) {
      return {
        ...acc,
        [token.pythId]: token,
      }
    }
    return acc
  }, {})
}

export const tokenStorkIdMapping = (chainId: number): Record<string, Token> => {
  const tokens = TOKENS[chainId]

  return tokens.reduce((acc, token: any) => {
    if (token.storkID) {
      return {
        ...acc,
        [token.storkID]: token,
      }
    }
    return acc
  }, {})
}

export const tokenbaseIdMapping = (chainId: number) => {
  const tokens = TOKENS[chainId]

  return tokens.reduce(
    (acc, token: any) => {
      if (token.baseId) {
        acc[token.baseId] = token
      }
      return acc
    },
    {} as Record<string, Token>,
  )
}

export const tokenPythIdMappingList = (chainId: number) =>
  Object.keys(tokenPythIdMapping(chainId))

export const getPriceFromPriceFeed = (priceFeed: any) => {
  const priceFeedInfo = priceFeed?.price
  const price = parseContractPrice(
    BigNumber.from(Number(priceFeedInfo.price)),
    Number(priceFeedInfo.expo) + 30,
  )

  return price
}

export const getConfFromPriceFeed = (priceFeed: any) => {
  const priceFeedInfo = priceFeed?.price
  const price = parseContractPrice(
    BigNumber.from(Number(priceFeedInfo.conf)),
    Number(priceFeedInfo.expo) + 30,
  )

  return price
}

export const getPriceFeedTokenInfo = ({
  _tokenPythIdMapping,
  tokenId,
  priceFeed,
  chainId,
}: {
  _tokenPythIdMapping: ReturnType<typeof tokenPythIdMapping>
  tokenId: string
  priceFeed: PriceFeed
  chainId: number
}): Record<string, TokenPriceInfo> => {
  const spreadReductionBps = priceStore.getState().spreadReductionBps
  const tokenInfo: Record<string, TokenPriceInfo> = {}
  const tokenPythAddress = '0x' + tokenId
  const spreadFactor = BigNumber.from(10000).sub(
    BigNumber.from(spreadReductionBps[tokenPythAddress] || 0),
  )

  const tokenInfoFromMapping = _tokenPythIdMapping[tokenPythAddress]
  const price = getPriceFromPriceFeed(priceFeed)
  const conf = getConfFromPriceFeed(priceFeed)
  const spread = BigNumber.from(conf).mul(spreadFactor).div(10000)

  if (tokenInfoFromMapping.address) {
    tokenInfo[tokenInfoFromMapping.address] = {
      maxPrice: price.add(spread),
      minPrice: price.sub(spread),
      conf: spread,
    }
  }

  const wrappedToken = getWrappedToken(chainId)
  const usdce = getV1Tokens(chainId)

  if (wrappedToken?.pythId === tokenPythAddress) {
    tokenInfo[NATIVE_TOKEN_ADDRESS] = tokenInfo[tokenInfoFromMapping.address]
  }

  if (usdce[0]?.pythId === tokenPythAddress) {
    tokenInfo[usdce[0].address] = tokenInfo[tokenInfoFromMapping.address]
  }
  return tokenInfo
}
