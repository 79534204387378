import { convertTokenAddress, getToken } from 'config/tokens'
import DataStore from 'abis/DataStore.json'
import SyntheticsReader from 'abis/SyntheticsReader.json'
import { getContract } from 'config/contracts'
import { BigNumber, ethers } from 'ethers'
import useSWR, { KeyedMutator, useSWRConfig } from 'swr'
import {
  MarketInfo,
  MarketsData,
  MarketsInfoData,
  getByKey,
  getContractMarketPrices,
  getMarketFullName,
  useMarkets,
} from 'domain/synthetics/markets'
import React, { useMemo } from 'react'
import { create } from 'zustand'
import { TokensData, useTokensData } from 'domain/synthetics/tokens'
import { useAccount } from 'wagmi'
import { useSyntheticsEvents } from 'context/SyntheticsEvents'
import {
  MAX_PNL_FACTOR_FOR_TRADERS_KEY,
  borrowingExponentFactorKey,
  borrowingFactorKey,
  claimableFundingAmountKey,
  fundingExponentFactorKey,
  fundingFactorKey,
  fundingIncreaseFactorPerSecondKey,
  fundingDecreaseFactorPerSecondKey,
  thresholdForStableFundingKey,
  thresholdForDecreaseFundingKey,
  minFundingFactorPerSecondKey,
  maxFundingFactorPerSecondKey,
  isMarketDisabledKey,
  maxPnlFactorKey,
  maxPoolAmountKey,
  maxPositionImpactFactorForLiquidationsKey,
  maxPositionImpactFactorKey,
  minCollateralFactorForOpenInterest,
  minCollateralFactorKey,
  openInterestInTokensKey,
  openInterestKey,
  openInterestReserveFactorKey,
  maxOpenInterestKey,
  poolAmountAdjustmentKey,
  poolAmountKey,
  positionFeeFactorKey,
  positionImpactExponentFactorKey,
  positionImpactFactorKey,
  positionImpactPoolAmountKey,
  reserveFactorKey,
  swapFeeFactorKey,
  swapImpactExponentFactorKey,
  swapImpactFactorKey,
  swapImpactPoolAmountKey,
  virtualMarketIdKey,
  virtualTokenIdKey,
  minPositionImpactPoolAmountKey,
  positionImpactPoolDistributionRateKey,
  maxShortPoolUsdForDepositKey,
  maxLongPoolUsdForDepositKey,
} from 'config/dataStore'
import { useMulticall } from 'rfx/lib/multicall'
import { IS_VERBOSE } from 'config/development'
import { getSyntheticsGraphClient } from 'rfx/lib/subgraph'
import { gql } from '@apollo/client'
import { DEFAULT_CHAIN_ID } from 'config/chains'
import json from 'json5'

export const stringify = (object: any) => {
  return json.stringify(object, {
    quote: '"',
  })
}

type MarketsStore = {
  marketsData: MarketsData
  marketsInfoData: MarketsInfoData
  tokensData: TokensData
  pricesUpdatedAt?: number
  mutate: KeyedMutator<MarketsInfoData>
  // setters
  setMarketsData: (marketsData?: MarketsData) => void
  setMarketsInfoData: (marketsInfoData?: MarketsInfoData) => void
  setMutate: (mutate?: KeyedMutator<MarketsInfoData>) => void
  setPricesUpdatedAt: (pricesUpdatedAt?: number) => void
  setTokensData: (tokensData?: TokensData) => void
}

export const whiteListedMarketAddresses = [
  '0x0b4D1d74890a860a7a3dF7769114bCeA7AA8B713',
  '0x4B1ef8Eb333FAE7CdaEc847475CC47bcDB70bF3f',
  '0x57ff14bD78d4B9B14E9aEC6e1D5d580d5DCa86ED',
  '0xBA06793bb5E3495c54330F5c5400C9AD14443586',
].map((item) => item.toLowerCase())

export const useMarketsStore = create<MarketsStore>((set) => ({
  marketsData: {},
  marketsInfoData: {},
  mutate: (() => {}) as any,
  pricesUpdatedAt: undefined,
  tokensData: {},
  // setters
  setMarketsData: (marketsData) => set({ marketsData }),
  setMarketsInfoData: (marketsInfoData) => set({ marketsInfoData }),
  setMutate: (mutate) => set({ mutate }),
  setPricesUpdatedAt: (pricesUpdatedAt) => set({ pricesUpdatedAt }),
  setTokensData: (tokensData) => set({ tokensData }),
}))

// const stringify = (object: any) => {
//   return json.stringify(object, {
//     quote: '"',
//   })
// }

export const useMarketsRequest = (withoutSupply?: Boolean) => {
  const chainId = DEFAULT_CHAIN_ID
  const client = getSyntheticsGraphClient(chainId)

  const key =
    chainId && client ? [chainId, 'useMarketsTokenSupply', withoutSupply] : null

  // const getFilter = (withoutSupply: Boolean) => {
  //   if (withoutSupply) {
  //     return {
  //       marketTokensSupply: '0',
  //     }
  //   }
  //   return {
  //     marketTokensSupply_not: '0',
  //   }
  // }
  const { data } = useSWR(key, {
    fetcher: async () => {
      try {
        const query = gql(`{
          marketInfos{
            shortToken
            marketToken
            longToken
            id
            indexToken
            marketTokensSupply
          }
      }`)

        const { data } = await client!.query({ query, fetchPolicy: 'no-cache' })

        return data.marketInfos.reduce(
          (
            acc: { marketsData: MarketsData; marketsAddresses: string[] },
            marketValues: any,
          ) => {
            try {
              const indexToken = getToken(
                chainId,
                convertTokenAddress(
                  chainId,
                  ethers.utils.getAddress(marketValues.indexToken),
                  'native',
                ),
              )
              const longToken = getToken(
                chainId,
                ethers.utils.getAddress(marketValues.longToken),
              )
              const shortToken = getToken(
                chainId,
                ethers.utils.getAddress(marketValues.shortToken),
              )

              const isSameCollaterals =
                marketValues.longToken === marketValues.shortToken
              const isSpotOnly =
                ethers.utils.getAddress(marketValues.indexToken) ===
                ethers.constants.AddressZero

              const name = getMarketFullName({
                indexToken,
                longToken,
                shortToken,
                isSpotOnly,
              })

              acc.marketsData[
                ethers.utils.getAddress(marketValues.marketToken)
              ] = {
                marketTokenAddress: ethers.utils.getAddress(
                  marketValues.marketToken,
                ),
                indexTokenAddress: ethers.utils.getAddress(
                  marketValues.indexToken,
                ),
                indexToken,
                shortToken,
                longToken,
                longTokenAddress: ethers.utils.getAddress(
                  marketValues.longToken,
                ),
                shortTokenAddress: ethers.utils.getAddress(
                  marketValues.shortToken,
                ),
                isSameCollaterals,
                isSpotOnly,
                poolName: name,
                name,
                data: '',
                marketTokensSupply: marketValues.marketTokensSupply,
              }

              acc.marketsAddresses.push(
                ethers.utils.getAddress(marketValues.marketToken),
              )
            } catch (e) {
              // eslint-disable-next-line no-console
              IS_VERBOSE && console.warn('unsupported market', e)
            }
            return acc
          },
          { marketsData: {}, marketsAddresses: [] },
        )
      } catch (err) {
        // eslint-disable-next-line no-console
        IS_VERBOSE && console.log('🚀 ~ fetcher: ~ err:', err)
        return {
          marketsData: [],
        }
      }
    },
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    revalidateIfStale: false,
    keepPreviousData: true,
  })

  const marketsData = data?.marketsData || {}
  return marketsData
}

export const useInitiateMarketsStore = () => {
  const { setMarketsData } = useMarketsStore()
  const marketsData = useMarketsRequest(false)

  React.useEffect(() => {
    setMarketsData(marketsData || {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(marketsData)])

  return null
}

export const useMarketsInfoData = (
  marketsData: MarketsData | undefined,
  marketsAddresses: string[] | undefined,
  marketsInfoData: MarketsInfoData,
) => {
  const { address: account } = useAccount()
  const chainId = DEFAULT_CHAIN_ID
  const { tokensData, pricesUpdatedAt, storkUpdatedAt } = useTokensData()
  const dataStoreAddress = getContract(chainId, 'DataStore')

  const { orderStatuses } = useSyntheticsEvents()

  const isDepencenciesLoading = !marketsAddresses || !tokensData

  const { requestKey, readerKey, dataStoreKey, valuesKey } = useMemo(() => {
    const orderStatusesKeys = Object.keys(orderStatuses || {})
      .filter(
        (item) => typeof orderStatuses[item]['executedTxnHash'] === 'string',
      )
      .join('-')

    const actualKey = !isDepencenciesLoading &&
      // !marketInfoStore &&
      marketsAddresses.length > 0 &&
      pricesUpdatedAt &&
      !!tokensData && [
        marketsAddresses.join('-'),
        dataStoreAddress,
        account,
        orderStatusesKeys,
        pricesUpdatedAt,
      ]

    if (actualKey) {
      return {
        valuesKey: [...actualKey].join(','),
        requestKey: [...actualKey, 'request'],
        readerKey: [...actualKey, 'readerKey'],
        dataStoreKey: [...actualKey, 'dataStoreKey'],
      }
    }
    return {}
  }, [
    orderStatuses,
    isDepencenciesLoading,
    marketsAddresses,
    dataStoreAddress,
    account,
    tokensData,
    pricesUpdatedAt,
    storkUpdatedAt,
  ])

  const { cache } = useSWRConfig()

  const { data, mutate } = useSWR<MarketsInfoData>(
    valuesKey,
    async (key) => {
      const dataFromCache = cache.get(key)

      if (dataFromCache?.data) {
        return dataFromCache.data
      }

      return marketsAddresses?.reduce((acc, marketAddress) => {
        const market = getByKey(marketsData, marketAddress)!
        const marketStore = marketsInfoData?.[marketAddress]
        const longToken = getByKey(tokensData!, market.longTokenAddress)!
        const shortToken = getByKey(tokensData!, market.shortTokenAddress)!
        const indexToken = getByKey(
          tokensData!,
          // market.indexTokenAddress
          convertTokenAddress(chainId, market.indexTokenAddress, 'native'),
        )!

        if (!longToken) {
          // eslint-disable-next-line no-console
          IS_VERBOSE && console.warn('missing long token', market)
          return acc
        }
        if (!shortToken) {
          // eslint-disable-next-line no-console
          IS_VERBOSE && console.warn('missing short token', market)
          return acc
        }
        if (!indexToken) {
          // eslint-disable-next-line no-console
          IS_VERBOSE && console.warn('missing index token', market)
          return acc
        }

        const marketPrices = getContractMarketPrices(tokensData!, market)

        if (!marketPrices) {
          // eslint-disable-next-line no-console
          IS_VERBOSE && console.warn('missed market prices', market)
          return acc
        }

        const marketInfo: MarketInfo = {
          ...market,
          // isDisabled: marketStore?.isDisabled || null,
          isDisabled: null,
          longToken,
          shortToken,
          indexToken,
          firstLp: market.firstLp,
          longInterestUsd: marketStore?.longInterestUsd || BigNumber.from(0),
          shortInterestUsd: marketStore?.shortInterestUsd || BigNumber.from(0),
          longInterestInTokens:
            marketStore?.longInterestInTokens || BigNumber.from(0),
          shortInterestInTokens:
            marketStore?.shortInterestInTokens || BigNumber.from(0),
          longPoolAmount: marketStore?.longPoolAmount || BigNumber.from(0),
          shortPoolAmount: marketStore?.shortPoolAmount || BigNumber.from(0),
          maxLongTokenPoolUsdForDeposit:
            marketStore?.maxLongTokenPoolUsdForDeposit || BigNumber.from(0),
          maxShortTokenPoolUsdForDeposit:
            marketStore?.maxShortTokenPoolUsdForDeposit || BigNumber.from(0),
          maxLongPoolAmount:
            marketStore?.maxLongPoolAmount || BigNumber.from(0),
          maxShortPoolAmount:
            marketStore?.maxShortPoolAmount || BigNumber.from(0),
          longPoolAmountAdjustment:
            marketStore?.longPoolAmountAdjustment || BigNumber.from(0),
          shortPoolAmountAdjustment:
            marketStore?.shortPoolAmountAdjustment || BigNumber.from(0),
          poolValueMin: marketStore?.poolValueMin || BigNumber.from(0),
          poolValueMax: marketStore?.poolValueMax || BigNumber.from(0),
          reserveFactorLong:
            marketStore?.reserveFactorLong || BigNumber.from(0),
          reserveFactorShort:
            marketStore?.reserveFactorShort || BigNumber.from(0),
          openInterestReserveFactorLong:
            marketStore?.openInterestReserveFactorLong || BigNumber.from(0),
          openInterestReserveFactorShort:
            marketStore?.openInterestReserveFactorShort || BigNumber.from(0),
          maxOpenInterestLong:
            marketStore?.maxOpenInterestLong || BigNumber.from(0),
          maxOpenInterestShort:
            marketStore?.maxOpenInterestShort || BigNumber.from(0),
          totalBorrowingFees:
            marketStore?.totalBorrowingFees || BigNumber.from(0),
          positionImpactPoolAmount:
            marketStore?.positionImpactPoolAmount || BigNumber.from(0),
          minPositionImpactPoolAmount:
            marketStore?.minPositionImpactPoolAmount || BigNumber.from(0),
          positionImpactPoolDistributionRate:
            marketStore?.positionImpactPoolDistributionRate ||
            BigNumber.from(0),
          swapImpactPoolAmountLong:
            marketStore?.swapImpactPoolAmountLong || BigNumber.from(0),
          swapImpactPoolAmountShort:
            marketStore?.swapImpactPoolAmountShort || BigNumber.from(0),
          borrowingFactorLong:
            marketStore?.borrowingFactorLong || BigNumber.from(0),
          borrowingFactorShort:
            marketStore?.borrowingFactorShort || BigNumber.from(0),
          borrowingExponentFactorLong:
            marketStore?.borrowingExponentFactorLong || BigNumber.from(0),
          borrowingExponentFactorShort:
            marketStore?.borrowingExponentFactorShort || BigNumber.from(0),
          fundingFactor: marketStore?.fundingFactor || BigNumber.from(0),
          fundingExponentFactor:
            marketStore?.fundingExponentFactor || BigNumber.from(0),
          fundingIncreaseFactorPerSecond:
            marketStore?.fundingIncreaseFactorPerSecond || BigNumber.from(0),
          fundingDecreaseFactorPerSecond:
            marketStore?.fundingDecreaseFactorPerSecond || BigNumber.from(0),
          thresholdForDecreaseFunding:
            marketStore?.thresholdForDecreaseFunding || BigNumber.from(0),
          thresholdForStableFunding:
            marketStore?.thresholdForStableFunding || BigNumber.from(0),
          minFundingFactorPerSecond:
            marketStore?.minFundingFactorPerSecond || BigNumber.from(0),
          maxFundingFactorPerSecond:
            marketStore?.maxFundingFactorPerSecond || BigNumber.from(0),
          pnlLongMax: marketStore?.pnlLongMax || BigNumber.from(0),
          pnlLongMin: marketStore?.pnlLongMin || BigNumber.from(0),
          pnlShortMax: marketStore?.pnlShortMax || BigNumber.from(0),
          pnlShortMin: marketStore?.pnlShortMin || BigNumber.from(0),
          netPnlMax: marketStore?.netPnlMax || BigNumber.from(0),
          netPnlMin: marketStore?.netPnlMin || BigNumber.from(0),

          maxPnlFactorForTradersLong:
            marketStore?.maxPnlFactorForTradersLong || BigNumber.from(0),
          maxPnlFactorForTradersShort:
            marketStore?.maxPnlFactorForTradersShort || BigNumber.from(0),

          minCollateralFactor:
            marketStore?.minCollateralFactor || BigNumber.from(0),
          minCollateralFactorForOpenInterestLong:
            marketStore?.minCollateralFactorForOpenInterestLong ||
            BigNumber.from(0),

          minCollateralFactorForOpenInterestShort:
            marketStore?.minCollateralFactorForOpenInterestShort ||
            BigNumber.from(0),

          claimableFundingAmountLong: undefined,

          claimableFundingAmountShort: undefined,

          positionFeeFactorForPositiveImpact:
            marketStore?.positionFeeFactorForPositiveImpact ||
            BigNumber.from(0),
          positionFeeFactorForNegativeImpact:
            marketStore?.positionFeeFactorForNegativeImpact ||
            BigNumber.from(0),
          positionImpactFactorPositive:
            marketStore?.positionImpactFactorPositive || BigNumber.from(0),
          positionImpactFactorNegative:
            marketStore?.positionImpactFactorNegative || BigNumber.from(0),
          maxPositionImpactFactorPositive:
            marketStore?.maxPositionImpactFactorPositive || BigNumber.from(0),
          maxPositionImpactFactorNegative:
            marketStore?.maxPositionImpactFactorNegative || BigNumber.from(0),
          maxPositionImpactFactorForLiquidations:
            marketStore?.maxPositionImpactFactorForLiquidations ||
            BigNumber.from(0),
          positionImpactExponentFactor:
            marketStore?.positionImpactExponentFactor || BigNumber.from(0),
          swapFeeFactorForPositiveImpact:
            marketStore?.swapFeeFactorForPositiveImpact || BigNumber.from(0),
          swapFeeFactorForNegativeImpact:
            marketStore?.swapFeeFactorForNegativeImpact || BigNumber.from(0),
          swapImpactFactorPositive:
            marketStore?.swapImpactFactorPositive || BigNumber.from(0),
          swapImpactFactorNegative:
            marketStore?.swapImpactFactorNegative || BigNumber.from(0),
          swapImpactExponentFactor:
            marketStore?.swapImpactExponentFactor || BigNumber.from(0),

          borrowingFactorPerSecondForLongs:
            marketStore?.borrowingFactorPerSecondForLongs || BigNumber.from(0),

          borrowingFactorPerSecondForShorts:
            marketStore?.borrowingFactorPerSecondForShorts || BigNumber.from(0),

          fundingFactorPerSecond:
            marketStore?.fundingFactorPerSecond || BigNumber.from(0),
          longsPayShorts: false,

          virtualPoolAmountForLongToken:
            marketStore?.virtualPoolAmountForLongToken || BigNumber.from(0),
          virtualPoolAmountForShortToken:
            marketStore?.virtualPoolAmountForShortToken || BigNumber.from(0),
          virtualInventoryForPositions:
            marketStore?.virtualInventoryForPositions || BigNumber.from(0),

          virtualMarketId: undefined,
          virtualLongTokenId: undefined,
          virtualShortTokenId: undefined,
        }
        acc[marketAddress] = marketInfo

        return acc
      }, {} as MarketsInfoData)
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      revalidateIfStale: false,
      keepPreviousData: true,
    },
  )

  const { data: requestInfo } = useSWR(
    requestKey,
    () => {
      return marketsAddresses!.reduce(
        (request, marketAddress) => {
          const market = getByKey(marketsData, marketAddress)!

          const marketPrices = getContractMarketPrices(tokensData!, market)

          if (!marketPrices) {
            // eslint-disable-next-line no-console
            IS_VERBOSE && console.warn('missed market prices', market)
            return request
          }

          const marketProps = {
            marketToken: market.marketTokenAddress,
            indexToken: market.indexTokenAddress,
            longToken: market.longTokenAddress,
            shortToken: market.shortTokenAddress,
          }

          request.reader[`${marketAddress}-reader`] = {
            contractAddress: getContract(chainId, 'SyntheticsReader'),
            abi: SyntheticsReader.abi,
            calls: {
              marketInfo: {
                methodName: 'getMarketInfo',
                params: [dataStoreAddress, marketPrices, marketAddress],
              },
              marketTokenPriceMax: {
                methodName: 'getMarketTokenPrice',
                params: [
                  dataStoreAddress,
                  marketProps,
                  marketPrices.indexTokenPrice,
                  marketPrices.longTokenPrice,
                  marketPrices.shortTokenPrice,
                  MAX_PNL_FACTOR_FOR_TRADERS_KEY,
                  true,
                ],
              },
              marketTokenPriceMin: {
                methodName: 'getMarketTokenPrice',
                params: [
                  dataStoreAddress,
                  marketProps,
                  marketPrices.indexTokenPrice,
                  marketPrices.longTokenPrice,
                  marketPrices.shortTokenPrice,
                  MAX_PNL_FACTOR_FOR_TRADERS_KEY,
                  false,
                ],
              },
            },
          }
          request.dataStore[`${marketAddress}-dataStore`] = {
            contractAddress: dataStoreAddress,
            abi: DataStore.abi,
            calls: {
              isDisabled: {
                methodName: 'getBool',
                params: [isMarketDisabledKey(marketAddress)],
              },
              longPoolAmount: {
                methodName: 'getUint',
                params: [poolAmountKey(marketAddress, market.longTokenAddress)],
              },
              shortPoolAmount: {
                methodName: 'getUint',
                params: [
                  poolAmountKey(marketAddress, market.shortTokenAddress),
                ],
              },
              maxLongPoolAmount: {
                methodName: 'getUint',
                params: [
                  maxPoolAmountKey(marketAddress, market.longTokenAddress),
                ],
              },
              maxShortPoolAmount: {
                methodName: 'getUint',
                params: [
                  maxPoolAmountKey(marketAddress, market.shortTokenAddress),
                ],
              },
              maxLongTokenPoolUsdForDeposit: {
                methodName: 'getUint',
                params: [
                  maxLongPoolUsdForDepositKey(
                    marketAddress,
                    market.longTokenAddress,
                  ),
                ],
              },
              maxShortTokenPoolUsdForDeposit: {
                methodName: 'getUint',
                params: [
                  maxShortPoolUsdForDepositKey(
                    marketAddress,
                    market.shortTokenAddress,
                  ),
                ],
              },
              longPoolAmountAdjustment: {
                methodName: 'getUint',
                params: [
                  poolAmountAdjustmentKey(
                    marketAddress,
                    market.longTokenAddress,
                  ),
                ],
              },
              shortPoolAmountAdjustment: {
                methodName: 'getUint',
                params: [
                  poolAmountAdjustmentKey(
                    marketAddress,
                    market.longTokenAddress,
                  ),
                ],
              },
              reserveFactorLong: {
                methodName: 'getUint',
                params: [reserveFactorKey(marketAddress, true)],
              },
              reserveFactorShort: {
                methodName: 'getUint',
                params: [reserveFactorKey(marketAddress, true)],
              },
              openInterestReserveFactorLong: {
                methodName: 'getUint',
                params: [openInterestReserveFactorKey(marketAddress, true)],
              },
              openInterestReserveFactorShort: {
                methodName: 'getUint',
                params: [openInterestReserveFactorKey(marketAddress, false)],
              },
              maxOpenInterestLong: {
                methodName: 'getUint',
                params: [maxOpenInterestKey(marketAddress, true)],
              },
              maxOpenInterestShort: {
                methodName: 'getUint',
                params: [maxOpenInterestKey(marketAddress, false)],
              },
              positionImpactPoolAmount: {
                methodName: 'getUint',
                params: [positionImpactPoolAmountKey(marketAddress)],
              },
              minPositionImpactPoolAmount: {
                methodName: 'getUint',
                params: [minPositionImpactPoolAmountKey(marketAddress)],
              },
              positionImpactPoolDistributionRate: {
                methodName: 'getUint',
                params: [positionImpactPoolDistributionRateKey(marketAddress)],
              },
              swapImpactPoolAmountLong: {
                methodName: 'getUint',
                params: [
                  swapImpactPoolAmountKey(
                    marketAddress,
                    market.longTokenAddress,
                  ),
                ],
              },
              swapImpactPoolAmountShort: {
                methodName: 'getUint',
                params: [
                  swapImpactPoolAmountKey(
                    marketAddress,
                    market.shortTokenAddress,
                  ),
                ],
              },
              borrowingFactorLong: {
                methodName: 'getUint',
                params: [borrowingFactorKey(marketAddress, true)],
              },
              borrowingFactorShort: {
                methodName: 'getUint',
                params: [borrowingFactorKey(marketAddress, false)],
              },
              borrowingExponentFactorLong: {
                methodName: 'getUint',
                params: [borrowingExponentFactorKey(marketAddress, true)],
              },
              borrowingExponentFactorShort: {
                methodName: 'getUint',
                params: [borrowingExponentFactorKey(marketAddress, false)],
              },
              fundingFactor: {
                methodName: 'getUint',
                params: [fundingFactorKey(marketAddress)],
              },
              fundingExponentFactor: {
                methodName: 'getUint',
                params: [fundingExponentFactorKey(marketAddress)],
              },
              fundingIncreaseFactorPerSecond: {
                methodName: 'getUint',
                params: [fundingIncreaseFactorPerSecondKey(marketAddress)],
              },
              fundingDecreaseFactorPerSecond: {
                methodName: 'getUint',
                params: [fundingDecreaseFactorPerSecondKey(marketAddress)],
              },
              thresholdForStableFunding: {
                methodName: 'getUint',
                params: [thresholdForStableFundingKey(marketAddress)],
              },
              thresholdForDecreaseFunding: {
                methodName: 'getUint',
                params: [thresholdForDecreaseFundingKey(marketAddress)],
              },
              minFundingFactorPerSecond: {
                methodName: 'getUint',
                params: [minFundingFactorPerSecondKey(marketAddress)],
              },
              maxFundingFactorPerSecond: {
                methodName: 'getUint',
                params: [maxFundingFactorPerSecondKey(marketAddress)],
              },
              maxPnlFactorForTradersLong: {
                methodName: 'getUint',
                params: [
                  maxPnlFactorKey(
                    MAX_PNL_FACTOR_FOR_TRADERS_KEY,
                    marketAddress,
                    true,
                  ),
                ],
              },
              maxPnlFactorForTradersShort: {
                methodName: 'getUint',
                params: [
                  maxPnlFactorKey(
                    MAX_PNL_FACTOR_FOR_TRADERS_KEY,
                    marketAddress,
                    false,
                  ),
                ],
              },
              claimableFundingAmountLong: account
                ? {
                    methodName: 'getUint',
                    params: [
                      claimableFundingAmountKey(
                        marketAddress,
                        market.longTokenAddress,
                        account,
                      ),
                    ],
                  }
                : undefined,
              claimableFundingAmountShort: account
                ? {
                    methodName: 'getUint',
                    params: [
                      claimableFundingAmountKey(
                        marketAddress,
                        market.shortTokenAddress,
                        account,
                      ),
                    ],
                  }
                : undefined,
              positionFeeFactorForPositiveImpact: {
                methodName: 'getUint',
                params: [positionFeeFactorKey(marketAddress, true)],
              },
              positionFeeFactorForNegativeImpact: {
                methodName: 'getUint',
                params: [positionFeeFactorKey(marketAddress, false)],
              },
              positionImpactFactorPositive: {
                methodName: 'getUint',
                params: [positionImpactFactorKey(marketAddress, true)],
              },
              positionImpactFactorNegative: {
                methodName: 'getUint',
                params: [positionImpactFactorKey(marketAddress, false)],
              },
              maxPositionImpactFactorPositive: {
                methodName: 'getUint',
                params: [maxPositionImpactFactorKey(marketAddress, true)],
              },
              maxPositionImpactFactorNegative: {
                methodName: 'getUint',
                params: [maxPositionImpactFactorKey(marketAddress, false)],
              },
              maxPositionImpactFactorForLiquidations: {
                methodName: 'getUint',
                params: [
                  maxPositionImpactFactorForLiquidationsKey(marketAddress),
                ],
              },
              minCollateralFactor: {
                methodName: 'getUint',
                params: [minCollateralFactorKey(marketAddress)],
              },
              minCollateralFactorForOpenInterestLong: {
                methodName: 'getUint',
                params: [
                  minCollateralFactorForOpenInterest(marketAddress, true),
                ],
              },
              minCollateralFactorForOpenInterestShort: {
                methodName: 'getUint',
                params: [
                  minCollateralFactorForOpenInterest(marketAddress, false),
                ],
              },
              positionImpactExponentFactor: {
                methodName: 'getUint',
                params: [positionImpactExponentFactorKey(marketAddress)],
              },
              swapFeeFactorForPositiveImpact: {
                methodName: 'getUint',
                params: [swapFeeFactorKey(marketAddress, true)],
              },
              swapFeeFactorForNegativeImpact: {
                methodName: 'getUint',
                params: [swapFeeFactorKey(marketAddress, false)],
              },
              swapImpactFactorPositive: {
                methodName: 'getUint',
                params: [swapImpactFactorKey(marketAddress, true)],
              },
              swapImpactFactorNegative: {
                methodName: 'getUint',
                params: [swapImpactFactorKey(marketAddress, false)],
              },
              swapImpactExponentFactor: {
                methodName: 'getUint',
                params: [swapImpactExponentFactorKey(marketAddress)],
              },
              longInterestUsingLongToken: {
                methodName: 'getUint',
                params: [
                  openInterestKey(marketAddress, market.longTokenAddress, true),
                ],
              },
              longInterestUsingShortToken: {
                methodName: 'getUint',
                params: [
                  openInterestKey(
                    marketAddress,
                    market.shortTokenAddress,
                    true,
                  ),
                ],
              },
              shortInterestUsingLongToken: {
                methodName: 'getUint',
                params: [
                  openInterestKey(
                    marketAddress,
                    market.longTokenAddress,
                    false,
                  ),
                ],
              },
              shortInterestUsingShortToken: {
                methodName: 'getUint',
                params: [
                  openInterestKey(
                    marketAddress,
                    market.shortTokenAddress,
                    false,
                  ),
                ],
              },
              longInterestInTokensUsingLongToken: {
                methodName: 'getUint',
                params: [
                  openInterestInTokensKey(
                    marketAddress,
                    market.longTokenAddress,
                    true,
                  ),
                ],
              },
              longInterestInTokensUsingShortToken: {
                methodName: 'getUint',
                params: [
                  openInterestInTokensKey(
                    marketAddress,
                    market.shortTokenAddress,
                    true,
                  ),
                ],
              },
              shortInterestInTokensUsingLongToken: {
                methodName: 'getUint',
                params: [
                  openInterestInTokensKey(
                    marketAddress,
                    market.longTokenAddress,
                    false,
                  ),
                ],
              },
              shortInterestInTokensUsingShortToken: {
                methodName: 'getUint',
                params: [
                  openInterestInTokensKey(
                    marketAddress,
                    market.shortTokenAddress,
                    false,
                  ),
                ],
              },
              virtualMarketId: {
                methodName: 'getBytes32',
                params: [virtualMarketIdKey(marketAddress)],
              },
              virtualLongTokenId: {
                methodName: 'getBytes32',
                params: [virtualTokenIdKey(market.longTokenAddress)],
              },
              virtualShortTokenId: {
                methodName: 'getBytes32',
                params: [virtualTokenIdKey(market.shortTokenAddress)],
              },
            },
          }

          return request
        },
        {
          reader: {} as Record<string, any>,
          dataStore: {} as Record<string, any>,
        },
      )
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
    },
  )

  useMulticall(chainId, 'useMarketsInfo-reader', {
    key: requestInfo?.reader ? readerKey : undefined,

    // Refreshed on every prices update
    refreshInterval: null,
    clearUnusedKeys: false,
    keepPreviousData: true,

    request: () => {
      return requestInfo!.reader
    },
    parseResponse: (res: any) => {
      mutate((data) => {
        const updatedData = { ...data }

        marketsAddresses!.forEach((marketAddress) => {
          const readerErrors = res.errors[`${marketAddress}-reader`]
          const readerValues = res.data[`${marketAddress}-reader`]
          // Skip invalid market
          if (!readerValues || readerErrors) {
            return
          }

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { nextFunding, virtualInventory } =
            readerValues.marketInfo.returnValues

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_priceMin, poolValueInfoMin] =
            readerValues.marketTokenPriceMin.returnValues

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_priceMax, poolValueInfoMax] =
            readerValues.marketTokenPriceMax.returnValues

          updatedData[marketAddress] = {
            ...updatedData[marketAddress],
            poolValueMin: BigNumber.from(poolValueInfoMin.poolValue),
            poolValueMax: BigNumber.from(poolValueInfoMax.poolValue),
            pnlLongMax: BigNumber.from(poolValueInfoMax.longPnl),
            pnlLongMin: BigNumber.from(poolValueInfoMin.longPnl),
            pnlShortMax: BigNumber.from(poolValueInfoMax.shortPnl),
            pnlShortMin: BigNumber.from(poolValueInfoMin.shortPnl),
            netPnlMax: BigNumber.from(poolValueInfoMax.netPnl),
            netPnlMin: BigNumber.from(poolValueInfoMin.netPnl),
            borrowingFactorPerSecondForLongs: BigNumber.from(
              readerValues.marketInfo.returnValues
                .borrowingFactorPerSecondForLongs,
            ),

            borrowingFactorPerSecondForShorts: BigNumber.from(
              readerValues.marketInfo.returnValues
                .borrowingFactorPerSecondForShorts,
            ),

            fundingFactorPerSecond: BigNumber.from(
              nextFunding.fundingFactorPerSecond,
            ),
            longsPayShorts: nextFunding.longsPayShorts,

            virtualPoolAmountForLongToken: BigNumber.from(
              virtualInventory.virtualPoolAmountForLongToken,
            ),
            virtualPoolAmountForShortToken: BigNumber.from(
              virtualInventory.virtualPoolAmountForShortToken,
            ),
            virtualInventoryForPositions: BigNumber.from(
              virtualInventory.virtualInventoryForPositions,
            ),
          }
        })

        return updatedData
      }, {})
    },
  })

  useMulticall(chainId, 'useMarketsInfo-datastore', {
    key: requestInfo?.dataStore ? dataStoreKey : undefined,

    // Refreshed on every prices update
    refreshInterval: null,
    clearUnusedKeys: true,
    keepPreviousData: true,

    request: () => requestInfo!.dataStore,
    parseResponse: (res: any) => {
      mutate((data) => {
        const updatedData = { ...data }

        marketsAddresses!.forEach((marketAddress) => {
          const dataStoreErrors = res.errors[`${marketAddress}-dataStore`]

          const dataStoreValues = res.data[`${marketAddress}-dataStore`]

          // Skip invalid market
          if (!dataStoreValues || dataStoreErrors) {
            return
          }

          const longInterestUsingLongToken = BigNumber.from(
            dataStoreValues.longInterestUsingLongToken.returnValues[0],
          )
          const longInterestUsingShortToken = BigNumber.from(
            dataStoreValues.longInterestUsingShortToken.returnValues[0],
          )
          const shortInterestUsingLongToken = BigNumber.from(
            dataStoreValues.shortInterestUsingLongToken.returnValues[0],
          )
          const shortInterestUsingShortToken = BigNumber.from(
            dataStoreValues.shortInterestUsingShortToken.returnValues[0],
          )

          const longInterestUsd = longInterestUsingLongToken.add(
            longInterestUsingShortToken,
          )
          const shortInterestUsd = shortInterestUsingLongToken.add(
            shortInterestUsingShortToken,
          )

          const longInterestInTokensUsingLongToken = BigNumber.from(
            dataStoreValues.longInterestInTokensUsingLongToken.returnValues[0],
          )
          const longInterestInTokensUsingShortToken = BigNumber.from(
            dataStoreValues.longInterestInTokensUsingShortToken.returnValues[0],
          )
          const shortInterestInTokensUsingLongToken = BigNumber.from(
            dataStoreValues.shortInterestInTokensUsingLongToken.returnValues[0],
          )
          const shortInterestInTokensUsingShortToken = BigNumber.from(
            dataStoreValues.shortInterestInTokensUsingShortToken
              .returnValues[0],
          )

          const longInterestInTokens = longInterestInTokensUsingLongToken.add(
            longInterestInTokensUsingShortToken,
          )
          const shortInterestInTokens = shortInterestInTokensUsingLongToken.add(
            shortInterestInTokensUsingShortToken,
          )

          updatedData[marketAddress] = {
            ...updatedData[marketAddress],
            // ...market,
            isDisabled: dataStoreValues.isDisabled.returnValues[0],
            // longToken,
            // shortToken,
            // indexToken,
            longInterestUsd,
            shortInterestUsd,
            longInterestInTokens,
            shortInterestInTokens,
            longPoolAmount: BigNumber.from(
              dataStoreValues.longPoolAmount.returnValues[0],
            ),
            shortPoolAmount: BigNumber.from(
              dataStoreValues.shortPoolAmount.returnValues[0],
            ),
            maxLongTokenPoolUsdForDeposit: BigNumber.from(
              dataStoreValues.maxLongTokenPoolUsdForDeposit.returnValues[0],
            ),
            maxShortTokenPoolUsdForDeposit: BigNumber.from(
              dataStoreValues.maxShortTokenPoolUsdForDeposit.returnValues[0],
            ),
            maxLongPoolAmount: BigNumber.from(
              dataStoreValues.maxLongPoolAmount.returnValues[0],
            ),
            maxShortPoolAmount: BigNumber.from(
              dataStoreValues.maxShortPoolAmount.returnValues[0],
            ),
            longPoolAmountAdjustment: BigNumber.from(
              dataStoreValues.longPoolAmountAdjustment.returnValues[0],
            ),
            shortPoolAmountAdjustment: BigNumber.from(
              dataStoreValues.shortPoolAmountAdjustment.returnValues[0],
            ),
            // poolValueMin: BigNumber.from(poolValueInfoMin.poolValue),
            // poolValueMax: BigNumber.from(poolValueInfoMax.poolValue),
            reserveFactorLong: BigNumber.from(
              dataStoreValues.reserveFactorLong.returnValues[0],
            ),
            reserveFactorShort: BigNumber.from(
              dataStoreValues.reserveFactorShort.returnValues[0],
            ),
            openInterestReserveFactorLong: BigNumber.from(
              dataStoreValues.openInterestReserveFactorLong.returnValues[0],
            ),
            openInterestReserveFactorShort: BigNumber.from(
              dataStoreValues.openInterestReserveFactorShort.returnValues[0],
            ),
            maxOpenInterestLong: BigNumber.from(
              dataStoreValues.maxOpenInterestLong.returnValues[0],
            ),
            maxOpenInterestShort: BigNumber.from(
              dataStoreValues.maxOpenInterestShort.returnValues[0],
            ),
            totalBorrowingFees: BigNumber.from(0),
            positionImpactPoolAmount: BigNumber.from(
              dataStoreValues.positionImpactPoolAmount.returnValues[0],
            ),
            minPositionImpactPoolAmount: BigNumber.from(
              dataStoreValues.minPositionImpactPoolAmount.returnValues[0],
            ),
            positionImpactPoolDistributionRate: BigNumber.from(
              dataStoreValues.positionImpactPoolDistributionRate
                .returnValues[0],
            ),
            swapImpactPoolAmountLong: BigNumber.from(
              dataStoreValues.swapImpactPoolAmountLong.returnValues[0],
            ),
            swapImpactPoolAmountShort: BigNumber.from(
              dataStoreValues.swapImpactPoolAmountShort.returnValues[0],
            ),
            borrowingFactorLong: BigNumber.from(
              dataStoreValues.borrowingFactorLong.returnValues[0],
            ),
            borrowingFactorShort: BigNumber.from(
              dataStoreValues.borrowingFactorShort.returnValues[0],
            ),
            borrowingExponentFactorLong: BigNumber.from(
              dataStoreValues.borrowingExponentFactorLong.returnValues[0],
            ),
            borrowingExponentFactorShort: BigNumber.from(
              dataStoreValues.borrowingExponentFactorShort.returnValues[0],
            ),
            fundingFactor: BigNumber.from(
              dataStoreValues.fundingFactor.returnValues[0],
            ),
            fundingExponentFactor: BigNumber.from(
              dataStoreValues.fundingExponentFactor.returnValues[0],
            ),
            fundingIncreaseFactorPerSecond: BigNumber.from(
              dataStoreValues.fundingIncreaseFactorPerSecond.returnValues[0],
            ),
            fundingDecreaseFactorPerSecond: BigNumber.from(
              dataStoreValues.fundingDecreaseFactorPerSecond.returnValues[0],
            ),
            thresholdForDecreaseFunding: BigNumber.from(
              dataStoreValues.thresholdForDecreaseFunding.returnValues[0],
            ),
            thresholdForStableFunding: BigNumber.from(
              dataStoreValues.thresholdForStableFunding.returnValues[0],
            ),
            minFundingFactorPerSecond: BigNumber.from(
              dataStoreValues.minFundingFactorPerSecond.returnValues[0],
            ),
            maxFundingFactorPerSecond: BigNumber.from(
              dataStoreValues.maxFundingFactorPerSecond.returnValues[0],
            ),

            maxPnlFactorForTradersLong: BigNumber.from(
              dataStoreValues.maxPnlFactorForTradersLong.returnValues[0],
            ),
            maxPnlFactorForTradersShort: BigNumber.from(
              dataStoreValues.maxPnlFactorForTradersShort.returnValues[0],
            ),

            minCollateralFactor: BigNumber.from(
              dataStoreValues.minCollateralFactor.returnValues[0],
            ),
            minCollateralFactorForOpenInterestLong: BigNumber.from(
              dataStoreValues.minCollateralFactorForOpenInterestLong
                .returnValues[0],
            ),

            minCollateralFactorForOpenInterestShort: BigNumber.from(
              dataStoreValues.minCollateralFactorForOpenInterestShort
                .returnValues[0],
            ),

            claimableFundingAmountLong:
              dataStoreValues.claimableFundingAmountLong
                ? BigNumber.from(
                    dataStoreValues.claimableFundingAmountLong?.returnValues[0],
                  )
                : undefined,

            claimableFundingAmountShort:
              dataStoreValues.claimableFundingAmountShort
                ? BigNumber.from(
                    dataStoreValues.claimableFundingAmountShort
                      ?.returnValues[0],
                  )
                : undefined,

            positionFeeFactorForPositiveImpact: BigNumber.from(
              dataStoreValues.positionFeeFactorForPositiveImpact
                .returnValues[0],
            ),
            positionFeeFactorForNegativeImpact: BigNumber.from(
              dataStoreValues.positionFeeFactorForNegativeImpact
                .returnValues[0],
            ),
            positionImpactFactorPositive: BigNumber.from(
              dataStoreValues.positionImpactFactorPositive.returnValues[0],
            ),
            positionImpactFactorNegative: BigNumber.from(
              dataStoreValues.positionImpactFactorNegative.returnValues[0],
            ),
            maxPositionImpactFactorPositive: BigNumber.from(
              dataStoreValues.maxPositionImpactFactorPositive.returnValues[0],
            ),
            maxPositionImpactFactorNegative: BigNumber.from(
              dataStoreValues.maxPositionImpactFactorNegative.returnValues[0],
            ),
            maxPositionImpactFactorForLiquidations: BigNumber.from(
              dataStoreValues.maxPositionImpactFactorForLiquidations
                .returnValues[0],
            ),
            positionImpactExponentFactor: BigNumber.from(
              dataStoreValues.positionImpactExponentFactor.returnValues[0],
            ),
            swapFeeFactorForPositiveImpact: BigNumber.from(
              dataStoreValues.swapFeeFactorForPositiveImpact.returnValues[0],
            ),
            swapFeeFactorForNegativeImpact: BigNumber.from(
              dataStoreValues.swapFeeFactorForNegativeImpact.returnValues[0],
            ),
            swapImpactFactorPositive: BigNumber.from(
              dataStoreValues.swapImpactFactorPositive.returnValues[0],
            ),
            swapImpactFactorNegative: BigNumber.from(
              dataStoreValues.swapImpactFactorNegative.returnValues[0],
            ),
            swapImpactExponentFactor: BigNumber.from(
              dataStoreValues.swapImpactExponentFactor.returnValues[0],
            ),

            virtualMarketId: dataStoreValues.virtualMarketId.returnValues[0],
            virtualLongTokenId:
              dataStoreValues.virtualLongTokenId.returnValues[0],
            virtualShortTokenId:
              dataStoreValues.virtualShortTokenId.returnValues[0],
          }
        })

        return updatedData
      }, false)
    },
  })

  return {
    marketsInfoData: data,
    tokensData,
    pricesUpdatedAt,
    storkUpdatedAt,
    marketsData: marketsData || {},
    mutate,
  }
}

export const useInitiateMarketsInfoData = () => {
  const { marketsData, marketsAddresses } = useMarkets()
  const { tokensData, pricesUpdatedAt, storkUpdatedAt } = useTokensData()
  const setMarketsInfoData = useMarketsStore(
    (store) => store.setMarketsInfoData,
  )
  const marketsInfoData = useMarketsStore((store) => store.marketsInfoData)

  const setMutate = useMarketsStore((store) => store.setMutate)
  const setPricesUpdatedAt = useMarketsStore(
    (store) => store.setPricesUpdatedAt,
  )
  const setTokensData = useMarketsStore((store) => store.setTokensData)

  const { marketsInfoData: data, mutate } = useMarketsInfoData(
    marketsData,
    marketsAddresses,
    marketsInfoData,
  )

  React.useEffect(() => {
    setMarketsInfoData(data || {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)])

  React.useEffect(() => {
    setTokensData(tokensData || {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tokensData)])

  React.useEffect(() => {
    setPricesUpdatedAt(pricesUpdatedAt)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricesUpdatedAt, storkUpdatedAt])

  React.useEffect(() => {
    setMutate(mutate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutate])

  return {
    marketsInfoData: data,
    tokensData,
    pricesUpdatedAt,
    storkUpdatedAt,
    marketsData: marketsData || {},
    mutate,
  }
}
