import { EvmPriceServiceConnection, PriceFeed } from '@pythnetwork/pyth-evm-js'
import commonStore from '@store/commonStore'
import priceStore from '@store/priceStore'
import { IS_VERBOSE } from 'config/development'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import useSWR from 'swr'
import {
  getConfFromPriceFeed,
  getPriceFeedTokenInfo,
  getPriceFromPriceFeed,
  tokenPythIdMapping,
  tokenStorkIdMapping,
} from './utils'
import { getContract } from 'config/contracts'
import { BigNumber, ethers } from 'ethers'
import PythABI from 'abis/PythABI.json'
import { DEFAULT_CHAIN_ID } from 'config/chains'
import { getProvider } from 'rfx/lib/rpc'
import { parseContractPrice } from 'domain/synthetics/tokens'

export const USD_DECIMALS = 30

const onPriceUpdate = (priceFeed: PriceFeed) => {
  const setTokenPrice = priceStore.getState().setTokenPrice
  try {
    const price = getPriceFromPriceFeed(priceFeed)
    const conf = getConfFromPriceFeed(priceFeed)
    const pythId = '0x' + priceFeed.id
    const spreadReductionBps = priceStore.getState().spreadReductionBps
    const spreadFactor = BigNumber.from(10000).sub(
      BigNumber.from(spreadReductionBps?.[pythId] || 0),
    )
    const spread = BigNumber.from(conf).mul(spreadFactor).div(10000)

    setTokenPrice(pythId, {
      maxPrice: price.add(spread),
      minPrice: price.sub(spread),
      conf: spread,
      ts: new Date().getTime(),
    })
  } catch (err) {
    // eslint-disable-next-line no-console
    IS_VERBOSE && console.log('🚀 ~ handleIncomingMessage ~ err:', err)
  }
}

export const querySpreadReductionBps = async (
  feedId: string,
): Promise<number> => {
  const provider = getProvider(undefined, DEFAULT_CHAIN_ID) as any

  const pythDataStreamProviderContract = new ethers.Contract(
    getContract(DEFAULT_CHAIN_ID, 'PythDataStreamProvider'),
    PythABI.abi,
    provider,
  )
  return await pythDataStreamProviderContract.spreadReductionBps(feedId)
}

const usePythPricesFetcher = (chainId: number) => {
  const connectionRef = useRef<EvmPriceServiceConnection>()

  const { pathname } = useRouter()
  const evmPriceServiceHttpConnection = new EvmPriceServiceConnection(
    'https://hermes.pyth.network',
    {},
  )
  const evmPriceServiceWsConnection = new EvmPriceServiceConnection(
    'https://hermes.pyth.network',
    {},
  )
  // const pythIdsForWS = priceStore((store) => store.pythIdsForWS)
  const setIsWSBlocked = commonStore((store) => store.setIsWSBlocked)
  const prices = commonStore((store) => store.prices)

  const setPricesUsingApi = commonStore((store) => store.setPricesUsingApi)
  const isWSBlocked = commonStore((store) => store.isWSBlocked)

  const initializeWs = async () => {
    try {
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.log('Pyth WS: Starting WS')
      await evmPriceServiceWsConnection.subscribePriceFeedUpdates(
        Object.keys(tokenPythIdMapping(chainId)),
        onPriceUpdate,
      )
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.log('Pyth WS: Started WS Successfully')
      setIsWSBlocked(false)
    } catch (err) {
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.log('Pyth WS: Failed to start WS', err)
      setIsWSBlocked(true)
    }
  }

  useEffect(() => {
    // @ts-ignore
    if (!connectionRef.current?.wsClient) {
      initializeWs()
    }
  }, [])

  useSWR(
    ['stork-price', tokenStorkIdMapping(chainId), chainId],
    async ([, mapPythId]) => {
      try {
        const response = await fetch('/stork/prices/latest?assets=USNUSD', {
          method: 'GET',
          headers: {
            Authorization: 'Basic cmZ4OnN0YXItZ2xhZC1tZWFzdXJlLWJlaGluZA==',
          },
        })
        const latestFeed = await response.json()
        const price = latestFeed?.data['USNUSD']?.price || '0'
        const priceInfo = {
          [mapPythId['USNUSD'].address]: {
            maxPrice: price
              ? parseContractPrice(BigNumber.from(price), 30 - 18)
              : BigNumber.from(0),
            minPrice: price
              ? parseContractPrice(BigNumber.from(price), 30 - 18)
              : BigNumber.from(0),
            conf: BigNumber.from(0),
          },
        }
        setPricesUsingApi({ ...prices, ...priceInfo })
        return {
          priceInfo,
          updatedAt: latestFeed?.data['USNUSD'].timestamp,
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        IS_VERBOSE && console.log({ e })
      }
    },
    {
      refreshInterval: isWSBlocked || pathname === '/earn' ? 4000 : undefined,
      revalidateOnFocus: isWSBlocked,
      keepPreviousData: true,
    },
  )

  useSWR(
    ['galeto-oracle-price', tokenPythIdMapping(chainId), chainId],
    async ([, mapPythId, chainId]) => {
      const connection = evmPriceServiceHttpConnection

      const latestFeed = (await connection.getLatestPriceFeeds(
        Object.keys(mapPythId),
      )) as PriceFeed[]
      let priceInfo = {}
      latestFeed.forEach((result) => {
        const tokenPriceInfo = getPriceFeedTokenInfo({
          _tokenPythIdMapping: tokenPythIdMapping(chainId),
          tokenId: result.id,
          priceFeed: result,
          chainId,
        })

        priceInfo = {
          ...tokenPriceInfo,
          ...priceInfo,
        }
      })

      setPricesUsingApi(priceInfo)

      return {
        priceInfo,
        updatedAt: latestFeed[0].getPriceUnchecked().publishTime,
      }
    },
    {
      refreshInterval: isWSBlocked || pathname === '/earn' ? 4000 : undefined,
      revalidateOnFocus: isWSBlocked,
      keepPreviousData: true,
    },
  )

  return {}
}

export default usePythPricesFetcher
