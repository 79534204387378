import { expandDecimals } from '@components/trade/rfx/tradingview/lib/numbers'
import { BigNumber, ethers } from 'ethers'
import { KeyedMutator } from 'swr'
import { TokensData } from '../tokens/types'
import {
  ContractMarketPrices,
  Market,
  MarketsData,
  MarketsInfoData,
} from './types'
import { useMarketsStore } from '@store/marketsStore'
import { Token } from 'domain/tokens'
import { useMemo } from 'react'

export type MarketsInfoResult = {
  marketsInfoData?: MarketsInfoData
  tokensData?: TokensData
  pricesUpdatedAt?: number
  marketsData: MarketsData
  mutate: KeyedMutator<MarketsInfoData>
}

export type TokenPrices = {
  minPrice: BigNumber
  maxPrice: BigNumber
}

export function convertToContractPrice(
  price: BigNumber,
  tokenDecimals: number,
) {
  if (!price || !tokenDecimals) {
    return
  }

  return price.div(expandDecimals(1, tokenDecimals))
}

export function convertToContractTokenPrices(
  prices: TokenPrices,
  tokenDecimals: number,
) {
  return {
    min:
      convertToContractPrice(prices.minPrice, tokenDecimals) ||
      BigNumber.from(0),
    max:
      convertToContractPrice(prices.maxPrice, tokenDecimals) ||
      BigNumber.from(0),
  }
}

export function getContractMarketPrices(
  tokensData: TokensData,
  market: Market,
): ContractMarketPrices | undefined {
  const { indexTokenAddress, longTokenAddress, shortTokenAddress } = market
  if (!indexTokenAddress || !longTokenAddress || !shortTokenAddress) {
    return undefined
  }

  const indexToken = getByKey(
    tokensData,
    ethers.utils.getAddress(market.indexTokenAddress),
  )
  const longToken = getByKey(
    tokensData,
    ethers.utils.getAddress(market.longTokenAddress),
  )
  const shortToken = getByKey(
    tokensData,
    ethers.utils.getAddress(market.shortTokenAddress),
  )

  if (!indexToken || !longToken || !shortToken) {
    return undefined
  }

  return {
    indexTokenPrice: convertToContractTokenPrices(
      indexToken.prices,
      indexToken.decimals,
    ),
    longTokenPrice: convertToContractTokenPrices(
      longToken.prices,
      longToken.decimals,
    ),
    shortTokenPrice: convertToContractTokenPrices(
      shortToken.prices,
      shortToken.decimals,
    ),
  }
}
export function getByKey<T>(
  obj?: { [key: string]: T },
  key?: string,
): T | undefined {
  if (!obj || !key) {
    return undefined
  }

  return obj[key]
}
export function useMarketsInfo(): MarketsInfoResult {
  const data = useMarketsStore((state) => state)

  return {
    marketsData: data.marketsData,
    marketsInfoData: data.marketsInfoData,
    pricesUpdatedAt: data.pricesUpdatedAt,
    tokensData: data.tokensData,
    mutate: data.mutate,
  }
}

export function useTokensDataWithoutPrices() {
  const { tokensData } = useMarketsInfo()

  const addresses = useMemo(() => Object.keys(tokensData || {}), [tokensData])

  return useMemo(
    () =>
      Object.values(tokensData || {}).reduce((acc, token) => {
        const {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          prices: _p,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          balance: _b,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          tag: _t,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          totalSupply: _ts,
          ...rest
        } = token
        acc[token.address] = rest

        return acc
      }, {} as TokensData<Token>),
    [addresses],
  )
}
