import { getTokensMap, getV2Tokens } from 'config/tokens'
import { useMemo } from 'react'
import { TokensData } from './types'
import { useTokenBalances } from './useTokenBalances'

import { usePythPrices } from 'hooks/rfx/usePyth/usePythPrices'
import { parseContractPrice } from './useTokenRecentPricesData'
import { BigNumber } from 'ethers'
import { XbyYPairs } from 'config/XbyYPairs'
import { useChainId } from 'rfx/lib/chains'

type TokensDataResult = {
  tokensData?: TokensData
  pricesUpdatedAt?: number
  storkUpdatedAt?: number
}

export function useTokensData(): TokensDataResult {
  const { chainId } = useChainId()
  const tokenConfigs = getTokensMap(chainId)
  const { balancesData } = useTokenBalances(chainId)
  const {
    pricesData,
    updatedAt: pricesUpdatedAt,
    storkUpdatedAt,
  } = usePythPrices(chainId)

  return useMemo(() => {
    const tokenAddresses =
      getV2Tokens(chainId) && getV2Tokens(chainId).map((token) => token.address)
    if (!pricesData) {
      return {
        tokensData: undefined,
        pricesUpdatedAt: undefined,
      }
    }

    return {
      tokensData: tokenAddresses?.reduce((acc: TokensData, tokenAddress) => {
        const balance = balancesData?.[tokenAddress]
        const tokenConfig = tokenConfigs[tokenAddress]
        let prices

        if (XbyYPairs.includes(tokenConfig.symbol)) {
          const tokenAddress1 = getV2Tokens(chainId).filter(
            (token) =>
              token.pythId === tokenConfig.pythId && !token?.baseAddress,
          )[0].address
          const tokenAddress2 = getV2Tokens(chainId).filter(
            (token) =>
              token?.pythId === tokenConfig.baseId && !token?.baseAddress,
          )[0].address

          const price = parseContractPrice(BigNumber.from(1), 30)
            .mul(pricesData[tokenAddress1]?.minPrice)
            .div(pricesData[tokenAddress2]?.minPrice)

          prices = {
            minPrice: price || BigNumber.from(0),
            maxPrice: price || BigNumber.from(0),
          }
        } else {
          prices = pricesData[tokenAddress]
        }

        if (!prices) {
          return acc
        }

        acc[tokenAddress] = {
          ...tokenConfig,
          prices,
          balance,
        }
        return acc
      }, {} as TokensData),
      pricesUpdatedAt,
      storkUpdatedAt,
    }
  }, [
    chainId,
    pricesData,
    pricesUpdatedAt,
    storkUpdatedAt,
    balancesData,
    tokenConfigs,
  ])
}
